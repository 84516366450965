import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  margin: 120px 0 80px;
  font-size: 14px;
`;

export const WrapperPartner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const PartnerDescription = styled.div`
  > h1 {
    font-size: 40px;
    line-height: 41px;
    color: #444444;

    @media screen and (max-width: 700px) {
      font-size: 22px;
      line-height: 24px;
      text-align: left;
    }
  }

  > p {
    width: 70%;
    font-size: 1.3rem;
    color: #444444;
    text-align: left;

    @media screen and (max-width: 700px) {
      width: 100%;
      font-size: 1rem;
      text-align: left;
      padding-right: 0;
      padding-left: 0;
    }
  }

  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

export const PartnerList = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 60px;

  @media screen and (max-width: 700px) {
    margin-top: 20px;
  }

  @media screen and (max-width: 370px) {
    flex-direction: column;
  }
`;

export const PartnerListImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  min-height: 160px;

  > img {
    max-width: 80%;
  }

  @media screen and (max-width: 700px) {
    width: 25%;
    min-height: 120px;
    border-radius: 15px;
    align-self: center;

    > img {
      width: 80%;
    }

    @media screen and (max-width: 370px) {
      width: unset;
      max-width: unset;
    }
  }
`;

export const PartnerListDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 82%;
  min-height: 160px;
  background: #f5f5f5 padding-box;
  border-radius: 29px;
  padding: 25px 30px;

  > h2 {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 14px;
    color: #444444;

    @media screen and (max-width: 700px) {
      width: 100%;
      text-align: center;
      font-size: 1rem;
      line-height: 1rem;
      text-align: left;
    }
  }

  > p {
    font-size: 18px;
    color: #444444;
    margin: 0;
    text-align: left;

    @media screen and (max-width: 700px) {
      font-size: 13px;
      text-align: left;
      pointer-events: none;
    }
  }

  @media screen and (max-width: 700px) {
    width: 100%;
    min-height: 110px;
    border-radius: 18px;
    padding: 17px;
  }
`;
