import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 50vh;
  margin: 130px 0;
  font-size: 14px;
`;

export const CarouselTitle = styled.div`
  > h1 {
    font-size: 40px;
    line-height: 41px;
    color: #444444;

    @media screen and (max-width: 700px) {
      font-size: 22px;
      line-height: 24px;
      text-align: left;
    }
  }

  > p {
    width: 50%;
    font-size: 19px;
    color: #444444;
    text-align: left;

    @media screen and (max-width: 700px) {
      width: 100%;
      font-size: 1rem;
      text-align: left;
      padding-right: 0;
      padding-left: 0;
    }
  }
`;

export const CarouselImages = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 325px;
  cursor: pointer;

  & .swiper-container {
    width: 80%;

    @media screen and (max-width: 768px) {
      width: 98%;
    }
  }

  & .swiper-wrapper {
    display: flex;
    justify-content: ${({ quantity }) =>
      quantity > 5 ? 'flex-start' : 'space-around'};
    align-items: center;
    width: 100%;
    margin-bottom: 30px;

    @media screen and (max-width: 1025px) {
      justify-content: flex-start;
    }
  }

  & .swiper-slide {
    text-align: center;
    width: auto;

    &::after {
      content: '';
      position: absolute;
      height: 3px;
      left: 50%;
      bottom: -20px;
      background: #707070;
      width: ${({ quantity }) =>
        (quantity === 1 && '0') ||
        (quantity === 2 && '250%') ||
        (quantity === 3 && '165%') ||
        (quantity === 4 && '130%') ||
        '100%'};
    }

    &:last-child::after {
      content: '';
      position: absolute;
      width: 0;
      height: 3px;
      left: 50%;
      bottom: -20px;
      background: red;
    }

    @media (min-width: 768px) and (max-width: 1025px) {
      &::after {
        content: '';
        position: absolute;
        height: 3px;
        left: 50%;
        bottom: -20px;
        background: #707070;
        width: ${({ quantity }) =>
          (quantity === 1 && '0') ||
          (quantity === 2 && '220%') ||
          (quantity === 3 && '135%') ||
          (quantity === 4 && '100%') ||
          '100%'};
      }
    }

    @media screen and (max-width: 768px) {
      &::after {
        content: '';
        position: absolute;
        height: 3px;
        left: 0;
        bottom: -20px;
        background: #707070;
        width: 1000%;
      }
    }
  }
`;

export const ContainerSwiper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 325px;
`;

export const CertificateBlock = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 206px;
  height: 206px;
  background: #f5f5f5;
  border-radius: 29px;

  ${({ option }) =>
    option
      ? css`
          border: 3px solid #00bfa5;
        `
      : css`
          border: none;
        `};

  img {
    max-width: 80%;
    max-height: 80%;
  }
`;

export const ContainerSwiperMobile = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    min-height: 50px;
    margin-top: -37px;
  }
`;

export const CarouselPointerMobile = styled.div`
  @media screen and (max-width: 768px) {
    width: 18px;
    height: 18px;
    background: #666666;
    border-radius: 50%;

    ${({ option }) =>
      option
        ? css`
            background: #00bfa5;
          `
        : css`
            background: #666666;
          `};
  }
`;

export const CarouselPointer = styled.div`
  position: absolute;
  bottom: -30px;
  z-index: 6;
  width: 24px;
  height: 24px;
  background: #666666;
  border-radius: 50%;

  ${({ option }) =>
    option
      ? css`
          background: #00bfa5;
        `
      : css`
          background: #666666;
        `};

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const CarouselDescription = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 330px;
  margin-top: -12px;
  background-color: #f5f5f5;
`;

export const CarouselDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  height: 250px;

  & h1 {
    font-size: 37px;
    margin: 10px 0 5px;
    color: #444444;
  }

  & p {
    font-size: 21px;
    font-weight: 400;
    color: #828ea0;
    text-align: center;
    line-height: 1.65rem;
  }

  @media screen and (max-width: 768px) {
    width: 90%;

    & h1 {
      font-size: 36px;
      margin: 10px 0 5px;
      color: #444444;
    }

    & p {
      font-size: 14px;
      line-height: 1.1rem;
    }
  }

  @media (min-width: 768px) and (max-width: 1025px) {
    /* width: 80%; */
  }
`;

export const CarouselDescriptionBoxUrl = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  position: relative;
  /* width: 85%; */
  height: 70px;
  background: #f5f5f5;
  box-shadow: 0 3px 20px #0057cc3b;
  border-radius: 19px;
  padding: 21px 35px;

  & > svg {
    width: 30px;
    height: 30px;
  }

  & a {
    z-index: 1;
    position: relative;
    padding: 3px 10px;
    font-size: 20px;
    color: #828ea0;
    text-decoration: none;
  }

  @media (min-width: 768px) and (max-width: 1025px) {
    & a {
      font-size: 16px;
      color: #828ea0;
      width: 90%;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media screen and (max-width: 768px) {
    /* width: 100%; */
    height: 60px;

    & > svg {
      width: 23px;
      height: 23px;
      margin-right: 5px;
    }

    & a {
      font-size: 16px;
      color: #828ea0;
      width: 90%;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const ShadowText = styled.div`
  position: absolute;
  z-index: 10;
  right: 30px;
  width: 150px;
  height: 50px;
  background-image: linear-gradient(to right, transparent 10%, #f5f5f5 70%);

  @media screen and (max-width: 768px) {
    right: 20px;
    width: 150px;
    height: 30px;
  }
`;
