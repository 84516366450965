import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './SecurityPartnerOperators.styles';
import Img from '@components/ImageWrapper';

const SecurityPartnerOperators = ({ title, description, partners }) => {
  return (
    <Styled.Wrapper>
      <Styled.WrapperPartner className="container">
        <Styled.PartnerDescription>
          <h1>{title}</h1>
          <p>{description}</p>
        </Styled.PartnerDescription>
        {partners.map((item, index) => (
          <Styled.PartnerList key={index}>
            <Styled.PartnerListImage>
              <Img src={item.image} alt="teste" type="image" />
            </Styled.PartnerListImage>
            <Styled.PartnerListDescription>
              <h2>{item.partners_title}</h2>
              <p>{item.partners_description}</p>
            </Styled.PartnerListDescription>
          </Styled.PartnerList>
        ))}
      </Styled.WrapperPartner>
    </Styled.Wrapper>
  );
};

SecurityPartnerOperators.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  partners: PropTypes.array.isRequired,
};

export default SecurityPartnerOperators;
