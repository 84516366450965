import styled from 'styled-components';

export const Wrapper = styled.section`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  min-height: 75vh;
  margin-bottom: -5rem;
  background-image: linear-gradient(to bottom, transparent 35%, #fff 80%),
    url(${({ img }) => img});
  background-size: 100%, 100%;
  background-repeat: no-repeat, no-repeat;
  background-position: top center;

  @media (min-width: 320px) and (max-width: 768px) {
    min-height: 30vh;
    margin-bottom: -3.5rem;
  }
`;

export const WrapperContent = styled.div.attrs({
  className: 'container',
})``;

export const HeaderText = styled.div`
  width: 60%;
  font-size: 64px;
  line-height: 72px;
  font-weight: bolder;
  color: ${({ color }) => color};
  text-shadow: ${({ shadow }) =>
    !!shadow ? '1px 1px 2px rgba(0,0,0,.34)' : 'none'};

  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 22px;
    line-height: 23px;
  }
`;
