// TODO: REFACTORING
import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './BulletPoints.styles';
import Img from '@components/ImageWrapper';

const BulletPoints = ({ title, description, image, index }) => {
  return (
    <Styled.Wrapper>
      <Styled.WrapperBullets className="container" direction={index}>
        <Styled.WrapperTexts direction={index}>
          <h1>{title}</h1>
          <p>{description}</p>
        </Styled.WrapperTexts>
        <Styled.BulletImage direction={index}>
          <Img src={image} alt={title} type="image" />
        </Styled.BulletImage>
      </Styled.WrapperBullets>
    </Styled.Wrapper>
  );
};

BulletPoints.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default BulletPoints;
