import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '@components/layout';
import BulletPoints from '@components/BulletPoints/BulletPoints';
import SecurityPartnerOperators from '@components/SecurityPartnerOperators/SecurityPartnerOperators';
import HeroSecurity from '@components/HeroSecurity/HeroSecurity';
import SecurityCarousel from '@components/SecurityCarousel';
import SectionArticles from '@components/SectionArticles';
import SEO from '@components/seo';

const Seguranca = ({ data, location }) => {
  const {
    wordpressPage: page,
    wordpressAcfPages: {
      acf: {
        security_hero: hero,
        security_bullet: bullet,
        security_partners: operators,
        security_articles: articles,
        security_certification: certified,
        security_associations: associations,
      },
    },
  } = data;

  return (
    <Layout
      dispatchedLang={location.state && location.state.dispatchLang}
      pageLang={page.polylang_current_lang}
      translations={page.polylang_translations}
      useTranslationsPath={true}
      showNewsletter={true}
      showFooter={true}
    >
      <SEO {...page} />
      <HeroSecurity {...hero} />
      {bullet.map((item, index) => (
        <BulletPoints {...item} index={index} key={index} />
      ))}
      <SecurityCarousel {...certified} />
      <SecurityCarousel {...associations} />
      <SecurityPartnerOperators {...operators} />
      <SectionArticles {...articles} />
    </Layout>
  );
};

export const query = graphql`
  query($wordpress_id: Int) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      content
      polylang_current_lang
      polylang_translations {
        path
        polylang_current_lang
      }
      yoast_head
    }
    wordpressAcfPages(wordpress_id: { eq: $wordpress_id }) {
      acf {
        security_hero {
          title
          text_color
          text_shadow
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        security_bullet {
          title
          description
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        security_partners {
          title
          description
          partners {
            partners_title
            partners_description
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        security_articles {
          posts {
            excerpt
            link
            title
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        security_certification {
          title
          description
          certifications {
            title
            description
            link
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        security_associations {
          title
          description
          certifications {
            title
            description
            link
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Seguranca;
