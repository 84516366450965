// TODO: REFACTORING
import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './HeroSecurity.styles';
import solveImageLink from '@utils/solveImageLink';

const HeroSecurity = ({ title, image, text_color, text_shadow }) => {
  image = solveImageLink(image);
  return (
    <Styled.Wrapper img={image && image.src}>
      <Styled.WrapperContent>
        <Styled.HeaderText color={text_color} shadow={text_shadow}>
          {title}
        </Styled.HeaderText>
      </Styled.WrapperContent>
    </Styled.Wrapper>
  );
};

HeroSecurity.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  text_color: PropTypes.string,
  text_shadow: PropTypes.bool,
};

export default HeroSecurity;
