import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import Icon from './Icon';
import Img from '@components/ImageWrapper';
import * as Styled from './SecurityCarousel.styles';

import { translate as t } from '@utils/translate';

const SecurityCarousel = ({ title, description, certifications }) => {
  const [information, setInformation] = useState([]);
  const swiperRef = useRef(null);
  const TEXTS = {
    ACCESS_SITE: t('Acessar o site'),
  };

  const getInformation = (title, index) => {
    let item = certifications.filter(item => {
      return item.title === title;
    });
    setInformation(...item, index);
  };

  const goToSlide = (title, index) => {
    getInformation(title, index);
    swiperRef.current.swiper.slideTo(index);
  };

  useEffect(() => {
    setInformation(...certifications);
  }, []);

  const params = {
    direction: 'horizontal',
    mousewheel: true,
    pagination: {
      clickable: true,
    },
    breakpoints: {
      1280: {
        slidesPerView: 5,
      },
      1024: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 2,
      },
      320: {
        slidesPerView: 1,
      },
    },
  };

  return (
    <Styled.Wrapper>
      <Styled.CarouselTitle className="container">
        <h1>{title}</h1>
        <p>{description}</p>
      </Styled.CarouselTitle>
      <Styled.CarouselImages quantity={certifications.length}>
        <Swiper {...params} ref={swiperRef}>
          {certifications.map((item, index) => (
            <Styled.ContainerSwiper key={index}>
              <Styled.CarouselPointer
                onClick={() => getInformation(item.title, index)}
                option={item.title === information.title}
              />
              <Styled.CertificateBlock
                option={item.title === information.title}
                onClick={() => getInformation(item.title, index)}
              >
                <Img src={item.image} alt={item.title} type="image" />
              </Styled.CertificateBlock>
            </Styled.ContainerSwiper>
          ))}
        </Swiper>
      </Styled.CarouselImages>
      <Styled.ContainerSwiperMobile>
        {certifications.map((item, index) => (
          <Styled.CarouselPointerMobile
            key={index}
            option={item.title === information.title}
            onClick={() => goToSlide(item.title, index)}
          />
        ))}
      </Styled.ContainerSwiperMobile>
      <Styled.CarouselDescription>
        <Styled.CarouselDescriptionContainer>
          <h1>{information.title}</h1>
          <p>{information.description}</p>
          <Styled.CarouselDescriptionBoxUrl>
            <Icon />
            <a
              href={information.link}
              target="_blank"
              rel="noopener noreferrer"
              title={information.link}
            >
              {TEXTS.ACCESS_SITE}
            </a>
            {/* <Styled.ShadowText /> */}
          </Styled.CarouselDescriptionBoxUrl>
        </Styled.CarouselDescriptionContainer>
      </Styled.CarouselDescription>
    </Styled.Wrapper>
  );
};

SecurityCarousel.propTypes = {
  certifications: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default SecurityCarousel;
